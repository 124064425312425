import myTransform from '@/libs_sz/utils/transform'
export default {
  data() {
    return {
      factoryList: [],
      workshopList: [],
      warehouseList: [],
      warehouseAreaList: [],
      areaList: [],
      productionLineList: [],
      deviceList: [],
      factoryPositionList: [],
      fixGoodsPositionList: [],
      deviceGoodsPositionList: [],
      classifySecondList: [],
      materialCodeList: []
    }
  },
  activated() {
    // this.initSelectList && this.initSelectList()
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getWorkshopList()
      this.getWarehouseList()
      this.getAreaList()
      this.getWarehouseAreaList()
      this.getFactoryPositionList()
      this.getProductionLineList()
      this.getDeviceList()
      this.getDeviceGoodsPositionList()
      this.getClassifyByFirstClassify()
      this.getMaterialCodeList()
    },
    /**
     * 编辑或复制时初始化下拉列表
     */
    initEditSelectList(row) {
      // console.log(row, 'rowwww')
      this.getWorkshopList(row.factoryId ? { factoryId: row.factoryId } : {})
      this.getWarehouseList(row.factoryId ? { factoryId: row.factoryId } : {})
      this.getAreaList(row.workshopId ? { workshopId: row.workshopId } : {})
      const warehouseAreaParams = row.workshopId ? { workshopId: row.workshopId } : row.warehouseId ? { warehouseId: row.warehouseId } : {}
      this.getWarehouseAreaList(warehouseAreaParams)
      const factoryPositionParams = row.areaId ? { areaId: row.areaId } : {}
      this.getFactoryPositionList(factoryPositionParams)
      this.getProductionLineList(row.areaId ? { areaId: row.areaId } : {})
      this.getDeviceList(row.productionLineId ? { productionLineId: row.productionLineId } : {})
      const deviceGoodsPositionParams = row.deviceId ? { deviceId: row.deviceId } : {}
      this.getDeviceGoodsPositionList(deviceGoodsPositionParams)
      const classifyParams = row.materialFirstClassifyId ? { parentId: row.materialFirstClassifyId } : {}
      this.getClassifyByFirstClassify(classifyParams)
      const materialParams = {}
      row.materialFirstClassifyId ? materialParams.classifyFirst = row.materialFirstClassifyId : ''
      row.materialSecondClassifyId ? materialParams.classifySecond = row.materialSecondClassifyId : ''
      this.getMaterialCodeList(materialParams)
    },
    /**
     * 获取车间列表
     */
    getWorkshopList(params) {
      this.getSelectOptions('getWorkshopDictList', params).then(
        (list) => (this.workshopList = list)
      )
    },
    /**
     * 获取仓库列表
     */
    getWarehouseList(params) {
      this.getSelectOptions('getWarehouseDictList', params).then(
        (list) => (this.warehouseList = list)
      )
    },
    /**
     * 获取库区列表
     */
    getWarehouseAreaList(params) {
      this.getSelectOptions('getWarehouseAreaDictList', params).then(
        (list) => (this.warehouseAreaList = list)
      )
    },
    /**
     * 获取区域列表
     */
    getAreaList(params) {
      this.getSelectOptions('getAreaDictList', params).then(
        (list) => (this.areaList = list)
      )
    },
    /**
     * 获取产线列表
     */
    getProductionLineList(params) {
      this.getSelectOptions('getProductionLineDictList', params).then(
        (list) => (this.productionLineList = list)
      )
    },
    /**
     * 获取设备列表
     */
    getDeviceList(params) {
      this.getSelectOptions('getDeviceDictList', params).then(
        (list) => (this.deviceList = list)
      )
    },
    /**
     * 获取设备货位列表
     */
    getDeviceGoodsPositionList(params) {
      this.$httpService(this.$apiStore.base('getGoodsPositionListByType'), { ...params, goodsPositionType: 'deviceGoodsPositionSec' }).then(({ data }) => {
        this.deviceGoodsPositionList = data.map(item => { return { label: item.code, value: item.id } })
      })
    },
    /**
     * 获取生产库位列表
     */
    getFactoryPositionList(params) {
      this.getSelectOptions('getFactoryPositionDictList', { ...params, factoryPositionType: 'factoryPositionTypeProduce' }).then(
        (list) => (this.factoryPositionList = list)
      )
    },
    /**
     * 获取库位列表
     */
    getNoUseFactoryPositionList(params) {
      this.getSelectOptions('getNoUseFactoryPositionDictList', params).then(
        (list) => (this.factoryPositionList = list)
      )
    },
    /**
     * 获取固定货位列表
     */
    getFixGoodsPositionList(params) {
      this.getSelectOptions(
        'getFixGoodsPositionByWarehouseAreaId',
        params
      ).then((list) => (this.fixGoodsPositionList = list))
    },

    /**
     * 获取容器类型列表
     * @param {*} params
     */
    async getContainerTypeList(params = {}) {
      await this.getSelectOptions('getContainerTypeDictList', params).then(
        (list) => (this.containerTypeList = list)
      )
    },
    // 根据一级分类级联二级分类
    async getClassifyByFirstClassify(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryClasifyByCodeOrName'), { ...params, level: 2 })
      if (code) return
      this.classifySecondList = myTransform.arrToOptions(data, 'classifyName', 'id')
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryMaterialOption'), params)
      if (code) return
      this.materialCodeList = data.map(item => { return { label: item.materialCode, value: item.id } })
    },
    /**
     * 一级分类-二级分类-物料编码级联
     * 一级级联二级和物料编码
     */
    handleClassifyFirstChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.materialSecondClassifyId = 0
      formData.materialId = 0
      const params = formData.materialFirstClassifyId ? { parentId: formData.materialFirstClassifyId } : {}
      const materialParams = formData.materialFirstClassifyId ? { classifyFirst: formData.materialFirstClassifyId } : {}
      this.getClassifyByFirstClassify(params)
      this.getMaterialCodeList(materialParams)
    },
    /**
     *二级级联--物料编码
     */
    handleClassifySecondChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.materialId = 0
      const materialParams = {}
      formData.materialFirstClassifyId ? materialParams.classifyFirst = formData.materialFirstClassifyId : ''
      formData.materialSecondClassifyId ? materialParams.classifySecond = formData.materialSecondClassifyId : ''
      this.getMaterialCodeList(materialParams)
    },
    /**
     * 获取数据列表
     * @param {*} apiName 接口名
     * @param {*} params 请求参数
     * @returns 选项列表
     */
    getSelectOptions(apiName, params) {
      const that = this
      return new Promise((resolve, reject) => {
        this.$httpService(this.$apiStore.base(apiName), params)
          .then(({ data, code }) => {
            if (code !== 0) {
              reject()
              return
            }
            const recordList = data || []
            const list = recordList.map((item) => {
              if (item.containerTypeCode) {
                return {
                  label: that.$t(item.containerTypeName),
                  value: item.id,
                  containerTypeCode: item.containerTypeCode,
                  containerTypePattern: item.containerTypePattern,
                  containerTypePatternType: item.containerTypePatternType
                }
              }
              if (item.factoryPositionCode) {
                return {
                  label: item.factoryPositionName,
                  value: item.id,
                  factoryPositionCode: item.factoryPositionCode,
                  factoryPositionType: item.factoryPositionType,
                  freezeFlag: item.freezeFlag
                }
              }
              return {
                label: that.$t(item.name || item.code),
                value: item.id
              }
            })
            resolve(list)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      // console.log(this.$refs.myMatchForm.formModel,"thisssssssssss")
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.workshopId = 0
      formData.warehouseId = 0
      formData.warehouseAreaId = 0
      formData.areaId = 0
      const params = formData.factoryId ? { factoryId: formData.factoryId } : {}
      this.getWorkshopList(params)
      this.getWarehouseList(params)
      this.getWarehouseAreaList(params)
      this.getAreaList(params)
      this.getFactoryPositionList(params)
      this.getProductionLineList(params)
      this.getDeviceList(params)
      this.getDeviceGoodsPositionList(params)
    },
    /**
     * 所属车间 change
     * 车间-库区和区域
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.warehouseAreaId = 0
      formData.warehouseId = 0
      const params = formData.workshopId ? { workshopId: formData.workshopId } : {}
      this.getWarehouseAreaList(params)
      this.getAreaList(params)
      this.getProductionLineList(params)
      this.getDeviceList(params)
      this.getDeviceGoodsPositionList(params)
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.warehouseAreaId = 0
      formData.workshopId = 0
      const params = formData.warehouseId ? { warehouseId: formData.warehouseId } : {}
      this.getWarehouseAreaList(params)
    },
    /**
     * 所属区域 change
     * 区域-产线
     * 区域-生产库位
     */
    handleAreaChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.productionLineId = 0
      formData.productionFactoryPositionId = 0
      const params = formData.areaId ? { areaId: formData.areaId } : {}
      this.getFactoryPositionList(params)
      this.getProductionLineList(params)
      this.getDeviceList(params)
      this.getDeviceGoodsPositionList(params)
    },
    /**
     * 库区 change
     * 库区-
     */
    handleWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
    },
    /**
     * 所属产线 change
     * 产线-设备
     */
    handleProductionLineChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      this.$refs.myMatchForm.formModel.deviceId = 0
      const params = formData.productionLineId ? { productionLineId: formData.productionLineId } : {}
      this.getDeviceList(params)
    },
    /**
     * 所属设备 change
     * 设备-设备货位
     */
    handleDeviceChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.deviceGoodsAllocationId = 0
      const params = formData.deviceId ? { deviceId: formData.deviceId } : {}
      this.getDeviceGoodsPositionList(params)
    }
  }
}
